import { template as template_853500b81d774c88bff56e37ca8ee99d } from "@ember/template-compiler";
const WelcomeHeader = template_853500b81d774c88bff56e37ca8ee99d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
