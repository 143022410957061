import { template as template_7f299d49e4f44adbbdc1d26a62f7a236 } from "@ember/template-compiler";
const FKLabel = template_7f299d49e4f44adbbdc1d26a62f7a236(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
