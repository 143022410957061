import { template as template_4bf3a11425fc435096cb9e120b0aa61f } from "@ember/template-compiler";
const FKText = template_4bf3a11425fc435096cb9e120b0aa61f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
