import { template as template_a791518563614ec18244a640ed66e2cd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_a791518563614ec18244a640ed66e2cd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
